<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">mdi-cellphone</v-icon>
          Users
          <v-spacer></v-spacer>
          <v-text-field
            v-model="paginationOptions.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe o Nome para buscar"
            single-line
            hide-details
            @keydown="buscar()"
            @click:append="buscar()"
          ></v-text-field>
          <v-spacer></v-spacer>
<!--          <v-switch-->
<!--            v-model="appUsers.active"-->
<!--            :label=" `Usuários ${appUsers.active? 'Ativos': 'Inativos'}`"-->
<!--            single-line-->
<!--            hide-details-->
<!--            class="mr-3"-->
<!--            style="width: 250px"-->
<!--            @change="buscar()"-->
<!--          ></v-switch>-->
<!--          -->
        </v-card-title>

        <v-card-text v-if="!appUsers.items">
          <v-row>
            <v-col md="12" sm="12" xs="12">
              <v-alert dense outlined type="warning" >
                Usuários do aplicativo não encontrados
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filteredAppUsersItems()"
          :options.sync="options"
          :server-items-length="appUsers.totalItems"
          :search.sync="paginationOptions.search"
          item-key="id"
          class="elevation-1"
          v-show="appUsers.items"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                          name: 'appUser',
                          params: { id: item.id }
                        }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";

import {APPUSERS_SEARCH} from "@/store/actions/appuser.type";
import {
  APPUSERS_RESET,
  SET_APPUSER_PAGINATION
} from "@/store/mutations/appuser.type";

export default {
  name: "AppUser",

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(APPUSERS_RESET),
      store.dispatch(APPUSERS_SEARCH)
    ]).then(() => {
      next();
    });
  },
  async beforeDestroy() {
      if(!this.$route.path.includes('/app-users')){
          localStorage.removeItem('appUsersOptions')
          localStorage.removeItem('appUserPaginationOptions')
      }
  },
  data: () => ({
    headers: [
      {text: "Nome", value: "name"},
      {text: "CPF", value: "cpf"},
      {text: "Celular", value: "cellPhone"},
      {text: "Data de Nascimento", value: "birthdate"},
      {text: "Data de Registro", value: "birthdate"},
      {
        text: "Visualizar",
        value: "actions",
        align: "center",
        sortable: false
      }
    ],

    selected: "",
    options: {},
    paginationOptions: {},
    //rules
    itemsRules: [
      // v => !!v || "Este campo é obrigatório",
      v =>
        (v && v <= 900) || "O valor deste campo não pode exceder mais que 900"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ]
  }),

  methods: {
    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options
      this.appUserPagination.search = this.paginationOptions.search

      store.commit(SET_APPUSER_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(APPUSERS_SEARCH);
    },
    filteredAppUsersItems() { 
        const items = this.appUsers.items
        if(this.appUserPagination.search){
            const search = this.appUserPagination.search.toLowerCase()
            this.options.itemsPerPage = this.appUsers.totalItems
            this.options.page = 1
            const exactMatches = [];
            const partialMatches = [];

            items.forEach(item => {
                const itemValues = Object.values(item).map(value => String(value).toLowerCase());
                const isExactMatch = itemValues.some(value => value.startsWith(search));
                const isPartialMatch = itemValues.some(value => value.includes(search));
                
                if (isExactMatch) {
                    exactMatches.push(item);
                } else if (isPartialMatch) {
                    partialMatches.push(item);
                }
            });

            return [...exactMatches,...partialMatches];
        }
        else {
            if(this.options.itemsPerPage == this.appUsers.totalItems){
                this.options.itemsPerPage = 10
            }
            return items;
        } 
    }
  },
  mounted() {
      if (localStorage.getItem('appUsersOptions')){
          this.options = JSON.parse(localStorage.getItem('appUsersOptions'))
      }
      if (localStorage.getItem('appUserPaginationOptions')){
          this.paginationOptions = JSON.parse(localStorage.getItem('appUserPaginationOptions'))
      }
  },
  watch: {
    options: {
      handler(newOptions) {
        this.buscar()
        const parsed = JSON.stringify(newOptions)
        localStorage.setItem('appUsersOptions', parsed)
      },
      deep: true,
    },
    paginationOptions: {
        handler(newSearch) {
            this.buscar()
            const parsed = JSON.stringify(newSearch)
            localStorage.setItem('appUserPaginationOptions', parsed)
        },
        deep: true,
    }
  },
  computed: {
    ...mapGetters(["checkUsers", "appUsers", "appUserPagination"])
  }
};
</script>
